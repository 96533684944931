import {
	createApp
} from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import 'animate.css';
import 'vant/lib/index.css';
import router from './router/index'
import {
	Button,
	Toast,
	NavBar,
	Icon,
	Loading,
	Field,
	Overlay,
	Popup,
	Dialog,
	Swipe,
	SwipeItem  
} from 'vant';

const app = createApp(App)
app.use(router)
app.use(Toast).use(Button).use(NavBar).use(Loading).use(Field).use(Overlay).use(Popup).use(Dialog).use(Swipe).use(SwipeItem)
app.mount('#app')

// createApp(App).use(Button).use(Toast).mount('#app')
