import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import {
	defineAsyncComponent
} from "vue"

// const success = (() => import('@/views/success.vue'))
const success = (() => import('@/views/successWx.vue'))

const routes = [
	{
		path: '/',
		name: 'success',
		component: success
	},

];


const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
